import { logger } from '@hatchd/utils';
import { Loader, Text } from 'components/ui';
import { useBuyerOrg } from 'hooks/use-buyer-org';
import { useStore } from 'lib/store';
import { NextPage } from 'next';
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { PATHS, SAFE_PATHS_NO_ORG } from 'settings/config';
import styled from 'styled-components';

type Options = {
  hideHeader?: boolean;
  hideNav?: boolean;
  overrideHeaderContent?: boolean;
  hideHomeLink?: boolean;
  marketplace?: boolean;
  app?: boolean;
};

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

/** Protect this page and prevent rendering if the current user session is invalid */
export function withAuth<T extends {} = {}>(
  WrappedComponent: NextPage<T>,
  options?: Options
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithAuth = (props: T) => {
    const { data: session, status } = useSession();
    const loading = status === 'loading';
    const router = useRouter();
    const activeBuyerOrgId = useStore((store) => store.activeBuyerOrgId);
    const setBuyerOrgId = useStore((store) => store.setActiveBuyerOrgId);
    const { data: buyerOrg } = useBuyerOrg();

    useEffect(() => {
      if (!buyerOrg && !activeBuyerOrgId && router?.query?.buyerOrgId) {
        setBuyerOrgId(router.query.buyerOrgId as string);
      }
    }, [router.query.buyerOrgId]);

    // Redirect the user to the org picker page if there is no active buyer org ID
    useEffect(() => {
      if (
        !activeBuyerOrgId &&
        !SAFE_PATHS_NO_ORG.includes(router.pathname) &&
        session?.accessToken
      ) {
        console.log('Redirect to org picker');
        logger.info('No buyer org set, redirecting to org picker page');
        router.push(PATHS.organisation.picker);
      }
    }, [activeBuyerOrgId, router.pathname, session?.accessToken]);

    // Redirect to login page, user is not authenticated
    useEffect(() => {
      if (!session && !loading) {
        signIn();
      }
    }, [loading, session]);

    // Render the passed in component
    if (session && (buyerOrg || SAFE_PATHS_NO_ORG.includes(router.pathname))) {
      return <WrappedComponent {...props} />;
    }

    return (
      <Wrapper>
        <Text textAlign={'center'} fontSize={5}>
          Authenticating
        </Text>
        <Loader size={5} />
      </Wrapper>
    );
  };

  ComponentWithAuth.displayName = `withAuth(${displayName})`;
  ComponentWithAuth.overrideHeaderContent = options?.overrideHeaderContent;
  ComponentWithAuth.hideNav = !!options?.hideNav;
  ComponentWithAuth.hideHeader = !!options?.hideHeader;
  ComponentWithAuth.hideHomeLink = !!options?.hideHomeLink;
  ComponentWithAuth.marketplace = !!options?.marketplace;
  ComponentWithAuth.app = !!options?.app;

  return ComponentWithAuth;
}
